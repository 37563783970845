.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff !important;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  height: 48px;
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.content {
  padding: 24px;
}

.mainCard {
  flex: auto;
  min-height: 80vh;
}
